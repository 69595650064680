<template>
  <div id="summary" class="card card-body">
    <div class="row align-items-center">
      <article-image-upload
        :url="visualImage"
        ref="imageUploadRef"
        @updated="onImageUpdated"
      />

      <div class="col">
        <h5 class="mb-1 font-weight-bolder">
          {{ title }}
        </h5>

        <p class="mb-0 font-weight-normal text-sm">
          {{ venueName }}

          <template v-if="venueName && parsedDateTimeRange">
            //
          </template>

          {{ dateString }} // {{ timeString }}
        </p>
      </div>
      <div class="user-selector-dropdown">
        <dropdown
          v-model:value="authorUserIdHandler"
          label="Auteur"
          :options="authors"
          :disabled="!enableAuthorSelection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArticleImageUpload from '@/components/Shared/ArticleImageUpload/ArticleImageUpload.vue';
import { createVModelHandlers } from "@/helpers/vue";
import { getDateString, getTimeString } from '@/helpers/datetime';

import Dropdown from '@/components/UI/Dropdown.vue';

import { getAll as getAllUsers } from '@/api/providers/users'

import { getDefaultAuthorId } from '@/helpers/organization-settings'

export default {
  name: 'NewsItemSummary',

  components: {
    ArticleImageUpload,
    Dropdown
  },
  
  props: {
    showUpdate: {
      type: Boolean,
      default: true,
    },
    createdDateTime: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true,
    },
    visualImage: {
      type: String,
      required: true,
    },
    authorUserId: {
      type: Number,
      required: true
    },
    visualImageData: {
      type: String,
      default: '',
    },
    enableAuthorSelection: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:visualImage',
    'update:visualImageData',
    'update:authorUserId'
  ],

  data() {
    return {
      authors: []
    }
  },
  
  computed: {
    ...createVModelHandlers(
      'authorUserId'
    ),

    dateString() {
      const dateString = getDateString(new Date(this.createdDateTime));
      return dateString !== '' ? dateString : 'Datum';
    },

    timeString() {
      const timeString = getTimeString(new Date(this.createdDateTime));
      return timeString !== '' ? timeString : 'Tijd';
    }
  },


  mounted() {
    getAllUsers()
      .then(apiUsers => {
        this.authors = apiUsers.filter(u => u.isAdmin || u.id === this.authorUserId).map(u => {
          return {
            value: u.id, 
            label: `${u.firstName ?? ''} ${u.insertion ?? ''} ${u.lastName != null ? u.lastName.charAt(0) : ''}.`,
            image: u.profilePicture
          }
        })

        getDefaultAuthorId()
          .then(defaultAuthorId => {
            const index = this.authors.findIndex(u => u.value == defaultAuthorId);

            this.authorUserIdHandler = index > -1
              	? this.authors[index].value
                : this.authors[0].value
          })
          .catch(() => this.authorUserIdHandler = this.authors[0].value)
      })
  },

  methods: {
    visualImageChanged({ target }) {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        this.$emit('update:visualImageData', target.result);
      };
      reader.readAsDataURL(target.files[0]);
    },

    onImageUpdated(data) {
      this.$emit('update:visualImageData', data);
    }
  },
};
</script>

<style scoped lang="scss">
.summary {
  &__image {
  
    &-column {
      width: 120px;
    }
  }
}

.summary-visual {

  &__image {
    aspect-ratio: 1;
    object-fit: cover;
  }

  &-remove {
    transition: opacity .2s;

    &:not(:hover) {
      opacity: 0;
    }

    &__background {
      opacity: .75;
    }
  }
}
.user-selector-dropdown {
  float:right;
  width:250px;
  .user-profile-image {
    margin-left: -3px;
    width:100px;
  }
}
</style>
