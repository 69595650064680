import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(data) {
  return postRequest('/newsitem', data);
}

export function edit(data) {
  return putRequest(`/newsitem/${data.id}`, data)
}

export function get(id) {
  return getRequest(`/newsitem/${id}`);
}

export function getAll() {
  return postRequest(
    '/newsitem/1',
    {
      "currentPage": 1,
      "pageSize": 8008135,
    },
  )
    .then(({ newsItems }) => newsItems);
}

export function remove(id) {
  return deleteRequest(`/newsitem/${id}`);
}
