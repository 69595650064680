<template>
  <div 
    class="col-lg-3"
  >
    <side-nav
      :items="navigationItems"
    />
  </div>

  <div
    class="col-lg-9 mt-lg-0 mt-4"
  >
    <news-item-summary
      v-model:visual-image="fields.visualImage"
      v-model:visual-image-data="fields.visualImageData"
      v-model:author-user-id="fields.createdByUserId"
      :created-date-time="fields.createDate"
      :title="fields.title"
      :enable-author-selection="isNewItem"
    />

    <form 
      @submit.prevent="submit"
    >
      <base-fieldset
        id="basic-info"
        title="Algemeen"
      >
        <basic-info
          v-model="fields"
          :show-update="!isNewItem"
        />
      </base-fieldset>

      <div 
        v-if="attachmentsModuleIsActive"
        id="attachments"
        class="card mt-4"
      >
        <div 
          class="card-body"
        >
          <file-upload
            v-model:files="files"
            attachment-type="0"
            :entity-id="entityId"
            @update:files="updateFiles"
          />
        </div>
      </div>

      <div
        v-if="photoGalleryModuleIsActive"
        id="photo-gallery"
        class="card mt-4">
        <div
          class="card-body"
        >
          <image-upload
            v-model:images="images"
            attachment-type="0"
            :entity-id="entityId"
            :show-update="!isNewItem"
            @update="updateImages"
          />
        </div>
      </div>

      <div 
        class="card mt-4"
      >
        <div 
          class="card-body"
        >
          <div 
            class="d-flex align-items-center mb-sm-0 mb-4"
          >
            <div 
              class="w-50"
            >
              <h5>Opslaan</h5>
            </div>
            <div 
              class="w-50 text-end"
            >
              <material-button
                :color="submitButtonColor"
                variant="gradient"
                class="float-end mt-4 mb-0 mx-4"
              >
                {{ submitButtonText }}
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isNewItem"
        id="delete"
        class="card mt-4"
      >
        <div 
          class="card-body"
        >
          <div 
            class="d-flex align-items-center mb-sm-0 mb-4"
          >
            <div 
              class="w-50"
            >
              <h5>Verwijder dit bericht</h5>
              <p
                class="text-sm mb-0"
              >
                Wanneer een bericht verwijderd wordt,
                kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div 
              class="w-50 text-end"
            >
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete Bericht
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { add, edit, get, remove } from '@/api/providers/news';

import { 
  add as addAttachment, 
  get as getAttachments, 
  edit as editAttachment,
  remove as removeAttachment
} from "@/api/providers/attachments/news";

import {
  get as getPhotoGalleryImages,
  add as addPhotoGalleryImages,
  remove as removePhotoGalleryImage
} from '@/api/providers/photo-gallery/news-item';

import BaseFieldset from '@/components/Forms/BaseFieldset.vue';
import BasicInfo from '@/components/Shared/BasicInfo/BasicInfo.vue';
import NewsItemSummary from '@/components/News/Edit/NewsItemSummary.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import SideNav from '@/components/UI/SideNav.vue';

import FileUpload from '@/components/UI/FileUpload.vue';
import ImageUpload from '@/components/UI/ImageUpload.vue';

import { createYesNoSweetAlert } from '@/helpers/sweetalert';
import {
  moduleIsActive,
  getModuleDisplayName
} from '@/helpers/module';
import setRouteNameForModule from '@/helpers/route-name';
import { CreateItemLabel, EditItemLabel } from '@/helpers/labels';

export default {
  name: 'NewsItemEdit',

  components: {
    BaseFieldset,
    BasicInfo,
    NewsItemSummary,
    MaterialButton,
    SideNav,
    FileUpload,
    ImageUpload
  },

  data: () => ({
    fields: {
      id: 0,
      organizationId: 0,
      createdByUserId: 0,

      title: '',
      description: '',
      publishDate: '',
      url: '',

      visualImageData: '',
      visualImageDataList: [],

      videoUrl: null
    },

    files: [],
    images: [],
    loading: false,

    photoGalleryModuleIsActive: false,
    attachmentsModuleIsActive: false,
  }),

  computed: {
    ...mapGetters('account', [
      'organizationId',
    ]),

    entityId() {
      return this.$route.params?.id ?? 0
    },

    isNewItem() {
      const fieldIdMissing = this.fields.id === 0;
      const routeIdMissing = this.$route.params?.id == null;

      return routeIdMissing || fieldIdMissing;
    },

    navigationItems() {
      return [
        {
          href: '#summary',
          icon: 'image',
          name: 'Samenvatting',
        },
        {
          href: '#basic-info',
          icon: 'notes',
          name: 'Algemeen',
        }
      ]
      .concat(
        this.attachmentsModuleIsActive
          ? {
            href: '#attachments',
            icon: 'folder',
            name: getModuleDisplayName('Attachments')
          }
          : []
      ).concat(
        this.photoGalleryModuleIsActive
          ? {
            href: '#photo-gallery',
            icon: 'image',
            name: 'Media'
          }
          : []
      );
    },

    submitData() {
      return {
        ...this.fields,
        organizationId: this.organizationId,
        defaultAuthorId: this.fields.createdByUserId,
      };
    },

    submitButtonColor() {
      return this.isNewItem ? 'primary' : 'dark'
    },

    submitButtonText() {
      return this.isNewItem ? 'Opslaan' : 'Update gegevens'
    }
  },

  beforeMount() {
    setRouteNameForModule(
      'News', 
      `Bericht ${ this.isNewItem ? CreateItemLabel : EditItemLabel }`
    );
  },

  async mounted() {
    this.photoGalleryModuleIsActive = await moduleIsActive("PhotoGallery");
    this.attachmentsModuleIsActive = await moduleIsActive("Attachments");

    await this.loadNewsItemData();

    if (this.isNewItem) {
      return;
    }

    if (this.photoGalleryModuleIsActive) this.loadNewsItemImages();
    if (this.attachmentsModuleIsActive) this.loadNewsItemAttachments();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    async editAttachments() {
      const files = this.files ?? [];

      files.filter(f => f.isUpdated && !f.isNew).forEach(async file => {
        try {
          await editAttachment(file.id, file.title);
        }
        catch (error) {
          throw new Error(error);
        }
      });
    },

    async uploadNewAttachments(newsItemId) {
      const files = this.files ?? [];

      files.filter(f => f.isNew && !f.isDeleted).forEach(async file => {
        try {
          await addAttachment(newsItemId, file.title, file.data);
        }
        catch (error) {
          throw new Error(error);
        }
      });
    },

    async deleteAttachments() {
      const files = this.files ?? [];

      files.filter(f => f.isDeleted && !f.isNew).forEach(async file => {
        try {
          await removeAttachment(file.id);
        }
        catch (error) {
          throw new Error(error);
        }
      });
    },

    async uploadImages(newsItemId) {
      await addPhotoGalleryImages(
        newsItemId,
        (this.images ?? [])
          .filter(i => i.isNew && !i.isDeleted)
          .map(i => i.data)
      );
    },

    async deleteImages() {
      (this.images ?? [])
        .filter(i => i.isDeleted && !i.isNew)
        .forEach(async i => {
          await removePhotoGalleryImage(i.id);
        });
    },

    async updateAttachmentsAndImages(newsItemId) {
      await this.updateAttachments(newsItemId);
      await this.updatePhotoGalleryImages(newsItemId);
    },

    async updateAttachments(newsItemId) {
      if (!this.attachmentsModuleIsActive) return;

      await this.uploadNewAttachments(newsItemId);
      await this.editAttachments(newsItemId);
      await this.deleteAttachments(newsItemId);
    },

    async updatePhotoGalleryImages(newsItemId) {
      if (!this.photoGalleryModuleIsActive) return;

      await this.uploadImages(newsItemId);
      await this.deleteImages(newsItemId);
    },

    addNewsItem() {
      add(this.submitData)
        .then(createdEntity => this.updateAttachmentsAndImages(createdEntity.id))
          .then(this.handleSaveSuccess)

        .catch(error => { if (error.default !== undefined) error.default(); })
        .then(() => this.loading = false);
    },

    editNewsItem() {
      edit(this.submitData)
        .then(this.updateAttachmentsAndImages(this.$route.params?.id))
          .then(this.handleSaveSuccess)
          .catch(error => { if (error.default !== undefined) error.default(); })

        .catch((error) => error.default())
        .then(() => {
          this.loading = false;
        });
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.fields[key] = value;
      });
    },

    handleGetFilesSuccess(data) {
      this.updateFiles(data);
    },

    handleGetImagesSuccess(data) {
      this.updateImages(data.photoGalleryImages.map(i => { 
        return {
          src: i.visualImage, 
          id: i.id
        }
      }));
    },

    handleRemoveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is verwijderd.`,
        icon: {
          component: 'event',
        },
      });

      this.navigateToNewsItemOverview();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is opgeslagen.`,
        icon: {
          component: 'news',
        },
      });

      this.navigateToNewsItemOverview();
    },

    async loadNewsItemData() {
      if (this.$route.params?.id == null) return; 

      try {
        let data = await get(this.$route.params.id);
        this.handleGetSuccess(data);
      }
      catch {
        this.addNotification({
          title: getModuleDisplayName("News"),
          description: 'Item kon niet worden gevonden',
          icon: {
            component: 'newspaper'
          }
        });
      }
    },

    loadNewsItemAttachments() {
      getAttachments(this.$route.params?.id)
        .then(this.handleGetFilesSuccess)
        .catch((error) => error.default());
    },

    loadNewsItemImages() {
      getPhotoGalleryImages(this.$route.params?.id)
        .then(this.handleGetImagesSuccess)
        .catch((error) => { if (error.default) error.default(); });
    },

    navigateToNewsItemOverview() {
      this.$router.push({
        name: 'news',
      });
    },

    remove() {
      createYesNoSweetAlert(
        `Nieuwsbericht ${this.fields.title} verwijderen?`,
        () => {
          remove(this.$route.params.id)
            .then(this.handleRemoveSuccess)
            .catch((error) => error.default());
        }
      )
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewItem) {
        this.addNewsItem();
      } else {
        this.editNewsItem();
      }
    },

    updateFiles(files) { this.files = files; },
    updateImages(images) { this.images = images; }
  },
};
</script>
